import React from 'react'
import { Button } from '@chakra-ui/react'
import Papa from 'papaparse'
import { useRSVPs } from '../../../hooks/useRSVPs'
import { useEventOccasionLabel } from '../../../hooks/useEventOccasionLabel'
import { useEventRecipients } from '../../../hooks/useRecipients'
import { useParams } from 'react-router-dom'
import { FileDownloadRounded } from '../Icon'

const ExportListButton = ({ ...rest }) => {
	const rsvps = useRSVPs()
	const recipients = useEventRecipients()
	const { uuid } = useParams()
	const occasionLabel = useEventOccasionLabel()
	const handleDownload = () => {
		// format the data
		let itemsFormatted = []
		rsvps.forEach((item) => {
			itemsFormatted.push({
				name: `${item.firstName} ${item.lastName ? item.lastName : ''}`,
				email: `${item.deliveryEmail}`,
				sms: `${item.deliverySms}`,
				status: item.archived ? 'removed' : item.status,
			})
		})
		var csv = Papa.unparse(itemsFormatted)

		/** Retrieve label  */
		var fileTitle = `VidDay-Invitation-List_${recipients.formatted.replace(
			/[^0-9a-zA-Z-_]/,
			'_'
		)}_${occasionLabel}_${uuid}`
		var exportedFilenmae = fileTitle + '.csv' || 'export.csv'

		var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
		if (navigator.msSaveBlob) {
			// IE 10+
			navigator.msSaveBlob(blob, exportedFilenmae)
		} else {
			var link = document.createElement('a')
			if (link.download !== undefined) {
				// feature detection
				// Browsers that support HTML5 download attribute
				var url = URL.createObjectURL(blob)
				link.setAttribute('href', url)
				link.setAttribute('download', exportedFilenmae)
				link.style.visibility = 'hidden'
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			}
		}
	}
	if (rsvps.length) {
		return (
			<Button variant="control" onClick={handleDownload} {...rest}>
				<FileDownloadRounded />
				Export List
			</Button>
		)
	} else {
		return <></>
	}
}

export default ExportListButton
