import React from 'react'
import {
	Modal,
	VStack,
	Heading,
	Button,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalBody,
} from '@chakra-ui/react'
import useImageGenerator from '../../../hooks/useImageGenerator'
import FormDeadline from '../../pages/Invitation/atoms/FormDeadline'

const ModalInvitationSetDeadline = ({ onClose, isOpen, event }) => {
	const onSubmitCallback = () => {
		try {
			if (typeof window != 'undefined' && localStorage) {
				const deadlineConfirmed = JSON.parse(localStorage.getItem('deadlineConfirmed')) || []
				if (!deadlineConfirmed.includes(event.uuid)) {
					deadlineConfirmed.push(event.uuid)
					localStorage?.setItem('deadlineConfirmed', JSON.stringify(deadlineConfirmed))
				}
			}
		} catch (err) {
			console.log("Can't access local storage : viewedmedia ", err)
		}
		onClose()
	}
	const img = useImageGenerator({
		path: '/assets/images/icons/spiral_calendar',
		ext: 'png',
	})

	return (
		<Modal
			onClose={onClose}
			isOpen={isOpen}
			scrollBehavior="outside"
			closeOnOverlayClick={false}
			closeOnEsc={false}
			isCentered={true}>
			<ModalOverlay />
			<ModalContent>
				<ModalBody>
					<VStack textAlign="center">
						<Image mb="1rem" maxW="80px" src={img.src} alt="Set a deadline" />
						<Heading size="lg" variant="hero">
							Confirm Due Date
						</Heading>
						<Text>Let's make sure the due date is spot on — you can adjust if needed.</Text>
						<Text></Text>
						<FormDeadline label="" onSubmit={onSubmitCallback} />
						<Text fontSize="sm">People can continue to submit past the due date.</Text>
					</VStack>
				</ModalBody>
				<HStack w="full" justifyContent="center" pt="1rem">
					<Button variant="solid" onClick={onSubmitCallback}>
						Confirm Due Date
					</Button>
				</HStack>
			</ModalContent>
		</Modal>
	)
}

export default ModalInvitationSetDeadline
