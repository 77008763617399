import React from 'react'
import { useEventHandlersV2 } from '../../../../../hooks/useEventHandlers'
import { NavCard, NavCardContent, NavCardCover, NavCardActions, NavCardButton } from '../../../../atoms/NavigationCard'
import { history } from '../../../../../../history'
import { useParams } from 'react-router-dom'
import { useRSVPs } from '../../../../../hooks/useRSVPs'

export const BroadcastSelector = ({ ...rest }) => {
	const rsvps = useRSVPs()
	const { uuid } = useParams()
	const handlers = useEventHandlersV2(uuid)
	const handleRedirectBroadcast = () => history.push(handlers.broadcast)

	const emailRsvps = rsvps.filter(
		(obj) => obj.deliveryEmail !== undefined && obj.deliveryEmail !== '' && obj.deliveryEmail !== null
	)

	if (emailRsvps.length > 0) {
		return (
			<NavCard onClick={handleRedirectBroadcast} {...rest}>
				<NavCardCover path={'/assets/images/graphics/navigation-cards/vidday-broadcast'} />
				<NavCardContent title={'Message your list'} description={'Send messages or reminders'} />
				<NavCardActions>
					<NavCardButton />
				</NavCardActions>
			</NavCard>
		)
	} else {
		return <></>
	}
}
