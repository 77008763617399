import React, { createContext, useContext, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

/** Build Current Event Context */
const TabsContext = createContext()

/** Define Current Event Context Provider  */
const TabsProvider = ({ baseUrl, tabs, active: defaultActive, children }) => {
	const [active, setActive] = useState(defaultActive || tabs[0].id)
	const navigate = useNavigate()
	const { tab } = useParams()
	const setActiveTab = (tab) => {
		/** Let's check first that we can find this tab */
		const activeTabIndex = tabs.findIndex((el, i) => el.id == tab)
		if (activeTabIndex == -1) {
			return new Error('This tab is not defined.')
		} else if (activeTabIndex != -1 && tab != active) {
			setActive(tabs[activeTabIndex].id)
			navigate(baseUrl + tabs[activeTabIndex].path)
		} else {
			// no changes, currently the same tab
		}
	}

	// Make sure we're on the right tab on mount
	useEffect(() => {
		if (tab != defaultActive) {
			navigate(baseUrl + '/' + defaultActive)
		}
	}, [])

	const value = {
		setActiveTab,
		active,
	}

	return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>
}

export default TabsProvider

export const useTabsContext = () => useContext(TabsContext)
