import React from 'react'
import { Tabs, TabList, Tab } from '@chakra-ui/react'
import { useEventHandlersV2 } from '../../../../../hooks/useEventHandlers'
import { useParams } from 'react-router-dom'
import { history } from '../../../../../../history'

const NavTabs = ({ defaultIndex }) => {
	const { uuid } = useParams()
	const handlers = useEventHandlersV2(uuid)
	return (
		<Tabs
			isFitted
			defaultIndex={defaultIndex}
			align="center"
			variant="unstyled"
			colorScheme="twitter"
			onChange={(index) =>
				index == 0 ? history.push(handlers.invitation) : history.push(`${handlers.invitation_list}/individual`)
			}>
			<TabList maxW="488px" borderRadius="full" bg="rgba(0,102,204,0.2)">
				<Tab
					borderRadius="full"
					fontWeight="600"
					color="link"
					_selected={{ color: 'link', bg: 'white', boxShadow: 'base' }}>
					Customize
				</Tab>
				<Tab
					borderRadius="full"
					fontWeight="600"
					color="link"
					_selected={{ color: 'link', bg: 'white', boxShadow: 'base' }}>
					Invite People
				</Tab>
			</TabList>
		</Tabs>
	)
}

export default NavTabs
