import React from 'react'
import { useEventContext } from '../../../contexts/EventProvider'
import Published from './Published'
import Active from './Active'
import { Routes, Route } from 'react-router-dom'

const InviteList = () => {
	const { event } = useEventContext()
	return (
		<Routes>
			<Route index element={event.status == 'published' ? <Published /> : <Active />} />
			<Route path=":tab" element={event.status == 'published' ? <Published /> : <Active />} />
		</Routes>
	)
}
export default InviteList
