import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import config from '../../../../../../../client/config.client'

// Client ID and API key from the Developer Console
var CLIENT_ID = config.gapi.clientID

// Authorization scopes required by the API multiple scopes can be included, separated by spaces.
var SCOPES = 'https://www.googleapis.com/auth/contacts.other.readonly https://www.googleapis.com/auth/contacts.readonly'
var MAX_RESULTS = '1000'

const GooglePeople = (props) => {
	const [loaded, setLoaded] = useState(false)

	const loadScript = (src, cb) => {
		const element = document.getElementsByTagName('script')[0]
		const fjs = element
		let js = element
		js = document.createElement('script')
		js.id = 'google-api'
		js.src = src
		js.async = true
		js.defer = true
		js.onload = () => {
			cb()
		}
		if (fjs && fjs.parentNode) {
			fjs.parentNode.insertBefore(js, fjs)
		} else {
			document.head.appendChild(js)
		}
	}

	// Load API client-script
	useEffect(() => {
		loadScript(props.jsSrc[0], () => setLoaded(true))
	}, [])

	const loadScopes = () => {
		let client = google.accounts.oauth2.initTokenClient({
			client_id: CLIENT_ID,
			scope: SCOPES,
			callback: (response) => {
				props.onLoading(true)
				localStorage.setItem('oauth2', JSON.stringify(response))
				list()
			},
		})
		client.requestAccessToken()
	}

	// If there's an access token, try an API request.
	// Otherwise, start OAuth 2.0 flow.
	const callScopesAPI = async (uri, key) => {
		return new Promise(function (resolve, reject) {
			var xhr = new XMLHttpRequest()
			xhr.open('GET', uri)
			xhr.onreadystatechange = function (e) {
				if (xhr.readyState === 4 && xhr.status === 200) {
					let data = JSON.parse(xhr.response)[key]
					if (data?.length) {
						resolve(data)
					} else {
						props.onMessage('No Contacts Found')
						resolve([])
					}
				} else if (xhr.readyState === 4 && xhr.status === 401) {
					// Token invalid, so prompt for user permission.
					loadScopes()
					props.onLoading(false)
				}
			}
			xhr.send(null)
			xhr.onerror = () => {
				console.log('ERROR', err)
				onFailure(err.result.error.message)
				props.onLoading(false)
				reject(err)
			}
		})
	}

	const list = async () => {
		var params = JSON.parse(localStorage.getItem('oauth2'))
		if (params && params['access_token']) {
			props.onLoading(true)

			let allContacts = []

			await callScopesAPI(
				`https://people.googleapis.com/v1/otherContacts
			?readMask=names,emailAddresses,phoneNumbers,photos
			&access_token=${params['access_token']}
			&pageSize=${MAX_RESULTS}
			`,
				'otherContacts'
			).then((response) => {
				allContacts.push(...response)
			})

			await callScopesAPI(
				`https://people.googleapis.com/v1/people/me/connections
			?access_token=${params['access_token']}
			&personFields=names,emailAddresses,phoneNumbers,photos
			&sortOrder=FIRST_NAME_ASCENDING
			&pageSize=${MAX_RESULTS}
			`,
				'connections'
			).then((response) => {
				allContacts.push(...response)
			})

			handleParseContacts(allContacts)
			props.onLoading(false)
		} else {
			loadScopes()
			props.onLoading(false)
		}
	}

	const handleParseContacts = (data) => {
		const { onSuccess } = props
		// Iterate over each contact.
		const results = []
		data.forEach((p) => {
			if (p?.emailAddresses || p?.phoneNumbers) {
				results.push({
					displayName: p?.names && p?.names[0].displayName,
					givenName: p?.names && p?.names[0].givenName,
					familyName: p?.names && p?.names[0].familyName,
					email: p?.emailAddresses && p?.emailAddresses[0].value,
					sms: p?.phoneNumbers && p?.phoneNumbers[0].value,
					photo: p.photos && p.photos[0].url,
				})
			}
		})

		results.sort(function (a, b) {
			const nameA = a.givenName
				? a.givenName?.toUpperCase()
				: a.displayName
				? a.displayName?.toUpperCase()
				: a.email.toUpperCase() // ignore case
			const nameB = b.givenName
				? b.givenName?.toUpperCase()
				: b.displayName
				? b.displayName?.toUpperCase()
				: b.email.toUpperCase() // ignore case
			// sort in an ascending order
			if (nameA < nameB) {
				return -1
			}
			if (nameA > nameB) {
				return 1
			}
			// names must be equal
			return 0
		})

		onSuccess(results)
	}

	const { render } = props
	if (render) {
		return render({ onClick: () => list(), loaded: loaded })
	}
}

GooglePeople.propTypes = {
	onSuccess: PropTypes.func.isRequired,
	onFailure: PropTypes.func.isRequired,
	onLoading: PropTypes.func.isRequired,
	render: PropTypes.func.isRequired,
	jsSrc: PropTypes.array,
}

GooglePeople.defaultProps = {
	jsSrc: ['https://accounts.google.com/gsi/client'],
}

export default GooglePeople

// TODO: followed this auth exanmple https://developers.google.com/people/quickstart/js
