import React from 'react'
import { HStack, Text, Tooltip, VStack, useDisclosure } from '@chakra-ui/react'
import { isMobile } from 'react-device-detect'
import {
	CheckCircleRounded,
	CheckCircleOutlineRounded,
	WatchLaterRounded,
	AccountCircleRounded,
	NotInterestedRounded,
	InfoRounded,
	RemoveCircleOutlineRounded,
	TimelapseRounded,
} from '../../../../atoms/Icon'
import { useAuthContext } from '../../../../../contexts/AuthProvider'

const getAdminStatus = (item) => {
	if (item.status == 'completed' && !item.archived) {
		if (item.fromUpload) {
			// system completed with media
			return 'completed'
		} else {
			// manual completed from email
			return 'participated'
		}
	} else if (item.status == 'participated' && !item.archived) {
		return 'participated'
	} else if (item.status == 'confirmed' && !item.archived) {
		return 'confirmed'
	} else if (item.status == 'declined' && !item.archived) {
		return 'declined'
	} else if (item.archived) {
		return 'removed'
	} else if (item.status == 'pending' && !item.archived) {
		return 'pending'
	} else {
		return null
	}
}

const rsvpStatuses = {
	pending: {
		icon: AccountCircleRounded,
		iconColor: '#6C7A88',
		text: 'Invited',
		tip: 'An invite has been sent out, auto-reminders are set.',
	},
	confirmed: {
		icon: WatchLaterRounded,
		iconColor: '#F6B031',
		text: "RSVP'd",
		tip: 'Invitee has RSVP to submit before the deadline.',
	},
	participated: {
		icon: CheckCircleOutlineRounded,
		iconColor: '#04D898',
		text: 'Participated',
		tip: 'Participated in a submitted piece of media.',
	},
	completed: {
		icon: CheckCircleRounded,
		iconColor: '#04D898',
		text: 'Submitted',
		tip: 'Invitee has submitted media. Click to see it.',
	},
	declined: {
		icon: NotInterestedRounded,
		iconColor: '#FF1C63',
		text: 'Declined',
		tip: 'Invitee has respectfully declined to partake.',
	},
	removed: {
		icon: NotInterestedRounded,
		iconColor: '#FF1C63',
		text: 'Removed',
		tip: 'Invitee was removed from list',
	},
}

const RsvpStatus = ({ item, onToggle, handleGoToMedia }) => {
	const { isAdmin } = useAuthContext()
	let Icon, iconColor, text, tip, status
	status = getAdminStatus(item)
	if (rsvpStatuses[status]) {
		Icon = rsvpStatuses[status].icon
		iconColor = rsvpStatuses[status].iconColor
		text = rsvpStatuses[status].text
		tip = rsvpStatuses[status].tip
	} else {
		Icon = RemoveCircleOutlineRounded
		iconColor = '#6C7A88'
		text = 'No Status'
		tip = 'Status unavaiable.'
	}

	const position = isMobile ? 'top' : 'left'

	return (
		<Tooltip maxW="414" mx=".5rem" hasArrow label={tip} aria-label={tip} placement={position}>
			<VStack>
				<Text
					onClick={status == 'completed' && item.fromUpload ? handleGoToMedia : onToggle}
					minW={['90px', '120px']}
					cursor="pointer"
					display="flex"
					alignItems="center"
					fontSize="0.75rem"
					// color={status == 'completed' && iconColor}
				>
					<Icon color={iconColor} mr={'0.25rem'} />
					{text}
				</Text>
				{isAdmin && <Text fontSize="0.75rem">original: {item.originalStatus}</Text>}
			</VStack>
		</Tooltip>
	)
}

export default RsvpStatus

const messageStatuses = {
	queued: {
		icon: TimelapseRounded,
		iconColor: '#6C7A88',
		text: 'Processing',
		tip: 'Message has been queued and will be delivered shortly.',
	},
	processed: {
		icon: TimelapseRounded,
		iconColor: '#6C7A88',
		text: 'Processing',
		tip: 'Message has been queued and will be delivered shortly.',
	},

	delivered: {
		icon: CheckCircleRounded,
		iconColor: '#04D898',
		text: 'Delivered',
		tip: 'Message has been successfully delivered.',
	},
	sent: {
		icon: CheckCircleOutlineRounded,
		iconColor: '#04D898',
		text: 'Sent',
		tip: 'Message has been successfully sent.',
	},

	bounced: {
		icon: InfoRounded,
		iconColor: '#FF1C63',
		text: 'Bounced',
		tip: 'Invalid email, we cannot deliver to this email due to a bounce, please check the email address.',
	},
	dropped: {
		icon: InfoRounded,
		iconColor: '#FF1C63',
		text: 'Dropped',
		tip: 'Email was dropped, this can happen if it has previously bounced. We recommend trying a different email.',
	},
	blocked: {
		icon: InfoRounded,
		iconColor: '#FF1C63',
		text: 'Blocked',
		tip: 'Email appears to be valid but it was blocked by the receiver.\nWe recommend trying a different email or sending method.',
	},

	not_delivered: {
		icon: InfoRounded,
		iconColor: '#FF1C63',
		text: 'Undelivered',
		tip: 'An error orccured during sending, please check the email or use a different sending method.',
	},
	undelivered: {
		icon: InfoRounded,
		iconColor: '#FF1C63',
		text: 'Undelivered',
		tip: 'Unable to send. The handset you are trying to reach is switched off or otherwise unavailable.',
	},
	failed: {
		icon: InfoRounded,
		iconColor: '#FF1C63',
		text: 'Failed',
		tip: 'Failed to send. The destination number is invalid or not within a supported country (USA & Canada only).',
	},
}

/**
 *
 * @param {string} status Status of the message
 * @param {func} onToggle Function to toggle the tooltip message
 * @returns {Component} Returns a component to display a message status
 */

export const MessageStatus = ({ status, children }) => {
	let Icon, iconColor, text, tip

	const { isOpen, onToggle } = useDisclosure()

	if (messageStatuses[status]) {
		Icon = messageStatuses[status].icon
		iconColor = messageStatuses[status].iconColor
		text = messageStatuses[status].text
		tip = messageStatuses[status].tip
	} else {
		Icon = RemoveCircleOutlineRounded
		iconColor = '#6C7A88'
		text = 'No Status'
		tip = 'Status unavaiable.'
	}

	return (
		<Tooltip
			isOpen={isMobile ? isOpen : undefined}
			maxW="414"
			mx=".5rem"
			hasArrow
			label={tip}
			aria-label={tip}
			placement={'top'}>
			<HStack onClick={onToggle} cursor="pointer">
				{children}
				<Text
					minW={'90px'}
					display="flex"
					ml={'0.25rem'}
					alignItems="center"
					fontSize="0.75rem"
					color={(status == 'delivered' || status == 'sent') && iconColor}>
					<Icon color={iconColor} w="1rem" mr={'0.25rem'} />
					{!isMobile && text}
				</Text>
			</HStack>
		</Tooltip>
	)
}
