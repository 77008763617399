import React from 'react'
import { Button } from '@chakra-ui/react'
import { PreviewRounded } from '../Icon'

const InvitationPreviewButton = ({ onClick, ...rest }) => {
	return (
		<Button variant="control" onClick={onClick} {...rest}>
			<PreviewRounded />
			Preview
		</Button>
	)
}

export default InvitationPreviewButton
