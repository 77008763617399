import React, { useEffect, useState } from 'react'
import { Flex, Stack, Box, Text, Image, Button, Link, chakra, Heading } from '@chakra-ui/react'
import useImageGenerator from '../../../../../hooks/useImageGenerator'
import Papa from 'papaparse'
import { useRSVPs } from '../../../../../hooks/useRSVPs'
import { useAuthContext } from '../../../../../contexts/AuthProvider'
import requests from '../../../../../../api/requests'
import ProcessContacts, { sanitizeSms } from '../../atoms/ProcessContacts'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { PHONE_POSSIBLE, PHONE_REGEX } from '../Individual/schema'
import { convertToE164 } from '../../../../../utils'

const FILE_SIZE = 200

const CSV = ({}) => {
	const rsvps = useRSVPs()
	const { event, isOwner } = useEventContext()
	const { email: creatorEmail, isImpersonated } = useAuthContext()

	const img = useImageGenerator({
		path: '/assets/images/graphics/csv-invitation-graphic',
		ext: 'png',
	})

	const [message, setMessage] = useState(null)
	const [results, setResults] = useState([])
	const [verifiedResults, setVerifiedResults] = useState([])

	const handleSelectCSV = ({ target }) => {
		const file = target.files[0]

		if (!file) {
			setMessage({ text: `File has no data.`, error: true })
		} else {
			/** Parse file and retrieve JSON */
			Papa.parse(file, {
				error: () => {
					setMessage({ text: `Error reading file.`, error: true })
				},
				complete: (results, file) => {
					// get data results
					let data = results.data
					if (data.length > FILE_SIZE) {
						setMessage({ text: `CSV limited to 200 contacts per upload`, error: true })
					} else {
						if (Array.isArray(data)) {
							setMessage({ text: 'Verifying data...' })
							let parsedArray = []
							// Parse columns, and only take first and second columns
							data.forEach((el, i) => {
								// Make sure we don't have a duplicated email
								const isDuplicate = parsedArray.findIndex(
									(ele) => (ele[1] && ele[1] == el[1]) || (ele[2] && ele[2] == el[2])
								)
								if (isDuplicate == -1) {
									// Only take items that contain a first name and an email or sms
									if (
										el[0] != '' &&
										(el[1] || el[2]) &&
										(el[1].includes('@') || PHONE_POSSIBLE.test(el[2]))
									) {
										if (el[0].length <= 100 && el[1].length <= 254) {
											parsedArray.push(el.slice(0, 3))
										}
									}
								}
							})
							let parsedArrayObjects = []
							// Using the parsedArray, turn into objects
							parsedArray.forEach((el, i) => {
								let firstName = el[0] ? el[0] : undefined
								// Check against current rsvp emails
								let email = el[1] ? el[1].toLowerCase() : undefined
								let existingEmail = email ? rsvps.find((rsvp) => email == rsvp?.deliveryEmail) : {}
								// Check against current rsvp sms without the +1
								let sms = el[2] ? convertToE164(sanitizeSms(el[2])) : undefined
								let existingSms = sms ? rsvps.find((rsvp) => sms == rsvp?.deliverySms) : {}

								let existingRsvp = existingEmail || existingSms

								const item = {
									id: existingRsvp?.id ? existingRsvp.id : i + 1,
									firstName: existingRsvp?.firstName ? existingRsvp.firstName : firstName,
									email: existingRsvp?.deliveryEmail ? existingRsvp.deliveryEmail : email,
									sms: existingRsvp?.deliverySms ? existingRsvp.deliverySms : sms,
									validSms: null,
									validEmail: null,
								}
								parsedArrayObjects.push(item)
							})
							setResults(parsedArrayObjects)
							setMessage({ text: 'Parsing completed.' })
						}
					}
				},
			})
		}
	}

	useEffect(() => {
		if (results.length > 0) {
			const loopMap = async () => {
				const promises = results.map(async (contact) => {
					const loop = new Promise((resolve, reject) => {
						let validEmail,
							validSms = true

						if (contact.sms) {
							// Check SMS
							var regex = new RegExp(PHONE_REGEX)
							validSms = regex.test(convertToE164(contact.sms))
						}
						if (contact.email) {
							// Check Email
							requests.verifySingleEmail({ email: contact.email }).then((validationResponse) => {
								validEmail =
									validationResponse.data.data.mx?.accepts_mail &&
									validationResponse.data.data.syntax?.is_valid_syntax == true &&
									(validationResponse.data.data.smtp?.can_connect_smtp ||
										validationResponse.data.data.is_reachable === 'unknown') &&
									(validationResponse.data.data.smtp?.is_deliverable ||
										validationResponse.data.data.is_reachable === 'unknown') &&
									validationResponse.data.data.is_reachable !== 'invalid'

								var updatedObject = {
									...contact,
									validEmail: validEmail,
									validSms: validSms,
									selected: !validEmail && !validSms ? false : true,
								}
								resolve(updatedObject)
							})
						} else {
							var updatedObject = {
								...contact,
								validEmail: validEmail,
								validSms: validSms,
								selected: !validEmail && !validSms ? false : true,
							}
							resolve(updatedObject)
						}
					})

					return loop
				})

				const verified = await Promise.all(promises)
				setVerifiedResults(verified)
			}
			loopMap()
			setMessage(null)
		}
	}, [results])

	if (results.length > 0) {
		return (
			<ProcessContacts
				data={verifiedResults}
				rsvps={rsvps}
				eventId={event.id}
				isCreator={isOwner || isImpersonated}
				creatorEmail={creatorEmail}
				onReset={() => {
					setResults([])
					setVerifiedResults([])
					setMessage(null)
				}}
			/>
		)
	}
	return (
		<Stack
			w="full"
			p="2rem"
			direction={['column', 'row']}
			justifyContent={['space-between', 'start']}
			alignItems="center"
			spacing="1rem">
			<Image src={img.src} srcSet={img.srcset} boxSize="99px" alt="google logo" />
			<Box w="full" textAlign={['center', 'left']}>
				<Heading as="h3" size="md" color={message?.error ? 'red' : ''}>
					{message?.text ? message?.text : 'Upload a CSV file'}
				</Heading>
				<Text color="#47596A" fontSize="0.875rem" lineHeight="1rem" marginBottom="0.25rem">
					<small>Send up to 200 invites at once.</small>
				</Text>
				<Text color="#47596A" fontSize="0.875rem" lineHeight="1.125rem">
					Column 1 of your file must be <strong>first name</strong>, column 2 <strong>email</strong> column 3{' '}
					<strong>sms/mobile</strong> — download a{' '}
					<Link
						variant="link"
						href="https://static.vidday.com/media_assets/vidday-invite-upload-template.csv"
						download="https://static.vidday.com/media_assets/vidday-invite-upload-template.csv">
						sample CSV template
					</Link>
					.
				</Text>
			</Box>
			<Flex w="full" justifyContent={['center', 'end']}>
				<chakra.label htmlFor="raised-button-file" minH="48px" display="inline-flex">
					<chakra.input
						accept="text/csv"
						onChange={handleSelectCSV}
						display="none"
						id="raised-button-file"
						name="raised-button-file"
						type="file"
					/>
					<Button minW="188px" variant="solid" pointerEvents="none">
						Select CSV file
					</Button>
				</chakra.label>
			</Flex>
		</Stack>
	)
}

export default CSV
