import React, { useState, useEffect } from 'react'
import { Image, Flex, Box, Text, Stack, Button, Heading, Spinner } from '@chakra-ui/react'
import useImageGenerator from '../../../../../hooks/useImageGenerator'
import GooglePeople from '../../atoms/GooglePeople'
import ProcessingContacts, { sanitizeSms } from '../../atoms/ProcessContacts'
import { useRSVPs } from '../../../../../hooks/useRSVPs'
import { useAuthContext } from '../../../../../contexts/AuthProvider'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { PHONE_REGEX } from '../Individual/schema'
import { convertToE164 } from '../../../../../utils'

const GoogleImport = () => {
	const rsvps = useRSVPs()
	const { email: creatorEmail } = useAuthContext()
	const {
		isOwner,
		event: { id: eventId },
	} = useEventContext()

	const img = useImageGenerator({
		path: '/assets/images/graphics/google-invitation-graphic',
		ext: 'png',
	})

	const [message, setMessage] = useState(null)
	const [data, setData] = useState(null)
	const [results, setResults] = useState([])
	const [loading, setLoading] = useState(false)

	const responseCallback = (response) => {
		setData(response)
	}

	const errorCallback = (error) => {
		console.error('ERROR', error)
		setMessage(`${error}`)
	}

	useEffect(() => {
		if (data) {
			let rows = []
			data.map((contact, i) => {
				// Check against current list
				let exists = rsvps.some((rsvp) => contact.email == rsvp?.user?.email || contact.email == creatorEmail)
				let regex = new RegExp(PHONE_REGEX)
				let sms = contact?.sms ? convertToE164(sanitizeSms(contact?.sms)) : null
				let validSms = regex.test(sms)
				let row = {
					id: i,
					firstName: contact?.givenName || contact?.displayName || null,
					lastName: contact?.familyName || contact?.displayName || null,
					email: contact?.email || null,
					sms: validSms ? sms : null,
					photo: contact?.photo || null,
					selected: false,
					exists: exists,
				}
				rows.push(row)
			})

			// Sort the Array by 'firstName'
			let sortedRows = rows.sort((a, b) => (a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0))

			setResults(sortedRows)
		}
	}, [data])

	if (loading) {
		return (
			<Flex w="full" p="2rem" direction="row" justifyContent="center" alignItems="center">
				<Heading size="md" as="h4">
					Loading contacts
				</Heading>
				<Spinner ml="2rem" />
			</Flex>
		)
	}

	if (results.length > 0) {
		return (
			<ProcessingContacts
				data={results}
				rsvps={rsvps}
				eventId={eventId}
				isCreator={isOwner}
				creatorEmail={creatorEmail}
				errorMessage={message}
				onReset={() => {
					setResults([])
					localStorage.removeItem('oauth2')
				}}
			/>
		)
	}

	return (
		<Stack
			w="full"
			p="2rem"
			direction={['column', 'row']}
			justifyContent={['space-between', 'start']}
			alignItems="center"
			spacing="1rem">
			<Image src={img.src} srcSet={img.srcset} boxSize="99px" alt="google logo" />
			<Box w="full" textAlign={['center', 'left']}>
				<Heading as="h3" size="md">
					Import from Google
				</Heading>
				<Text color="#47596A" fontSize="1rem" lineHeight="1.25rem" mt=".25rem">
					Connect to your Google account to access and select individual contacts.
				</Text>
			</Box>
			<Flex w="full" justifyContent={['center', 'end']}>
				<GooglePeople
					render={(p) => {
						return (
							// <Box id="google_btn" />
							<Button
								variant="solid"
								bg="white"
								color="link"
								boxShadow="base"
								onClick={p.onClick}
								disabled={!p.loaded}
								_hover={{ bg: 'gray.50' }}
								_active={{ bg: 'gray.50' }}>
								<Image src="/assets/images/graphics/logo-google-button.svg" mr="0.5rem" />
								Continue with Google
							</Button>
						)
					}}
					onSuccess={responseCallback}
					onFailure={errorCallback}
					onMessage={setMessage}
					onLoading={setLoading}
				/>
			</Flex>
		</Stack>
	)
}

export default GoogleImport
