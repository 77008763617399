import React, { useState } from 'react'
import { Image, Checkbox, Flex, Box, Text, VStack, HStack } from '@chakra-ui/react'
import { ErrorRounded } from '../../../../atoms/Icon'

const ContactPickerItem = ({ item, handleSelect }) => {
	const { firstName, lastName, email, sms, photo, id, selected, validEmail, validSms } = item
	const [colorRdm] = useState(Math.floor(Math.random() * 16777215).toString(16))
	const isGray = !selected
	return (
		<Box
			w="full"
			// onClick={validEmail || validSms ? () => handleSelect(id) : () => {}}
			__css={{
				'*': {
					cursor: validEmail || validSms ? 'pointer !important' : 'default',
				},
			}}>
			<Flex justify="space-between">
				<HStack spacing="1rem">
					{photo && (
						<Image
							borderRadius="full"
							src={photo}
							fallbackSrc={<Box w="100" h="100" bg={`#${colorRdm}`} />}
							boxSize="50px"
							backgroundColor="gray.200"
						/>
					)}
					<VStack alignItems="flex-start" spacing="0">
						<Text
							userSelect="none"
							fontSize="0.875rem"
							fontWeight="bold"
							noOfLines={1}
							color={isGray ? '#B5BCC3' : 'inherit'}>
							{firstName} {lastName}
						</Text>
						<Text
							userSelect="none"
							fontSize="0.875rem"
							noOfLines={1}
							color={isGray ? '#B5BCC3' : 'inherit'}>
							{email}{' '}
							{validEmail === false && (
								<Text as="span" color="red" display="inline" mr=".5rem">
									<ErrorRounded mr="0.25rem" w="1rem" /> Invalid
								</Text>
							)}
						</Text>
						<Text
							userSelect="none"
							fontSize="0.875rem"
							noOfLines={1}
							color={isGray ? '#B5BCC3' : 'inherit'}>
							{sms}{' '}
							{validSms === false && (
								<Text as="span" color="red" display="inline" mr=".5rem">
									<ErrorRounded mr="0.25rem" w="1rem" /> Invalid
								</Text>
							)}
						</Text>
					</VStack>
				</HStack>
				<Flex align="center" ml="1rem">
					{(validEmail || validSms) && <Checkbox isChecked={selected} onChange={() => handleSelect(id)} />}
				</Flex>
			</Flex>
		</Box>
	)
}

export default ContactPickerItem
