import React from 'react'
import NavBar from '../../../atoms/NavBar'
import { Box, Flex, Container, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../../atoms/nav-controls/BackButton'
import Card from '../../../atoms/Card'
import RSVPStatusFilter from '../atoms/RSVPStatusFilter'
import RSVPList from '../atoms/RSVPList'
import { useEventContext } from '../../../../contexts/EventProvider'
import { Helmet } from 'react-helmet'
import { useRSVPFiltered } from '../../../../hooks/useRSVPs'
import { useEventHandlersV2 } from '../../../../hooks/useEventHandlers'
import { BroadcastSelector } from '../../Broadcast/selectors/BroadcastSelector'
import FooterBase from '../../../molecules/FooterBase'
import ExportListButton from '../../../atoms/nav-controls/ExportListButton'

const PublishedInviteList = () => {
	const { event } = useEventContext()
	const handlers = useEventHandlersV2(event.uuid)
	const navigate = useNavigate()
	const { rsvps, collection } = useRSVPFiltered()

	return (
		<>
			<Helmet>
				<title>VidDay - Participant List</title>
			</Helmet>

			<NavBar>
				<Flex>
					<BackButton onClick={() => navigate(handlers.root)} />
				</Flex>
				<Flex justifyContent="flex-end">
					<ExportListButton />
				</Flex>
			</NavBar>

			<Container maxW="container.lg" py="1rem">
				<Box my="2rem" textAlign="center" mx={['1rem', 0]}>
					<Heading as="h2" size="xl" variant="hero">
						Participant List
					</Heading>

					<Text mb="1rem">
						With the click of a button, you can notify your participants that the video is ready to watch.
					</Text>
				</Box>

				<Card p={['1rem', '2rem']} mb="1rem" alignItems="center">
					<RSVPStatusFilter rsvps={rsvps} mb="2rem" hideRemoved />
					<RSVPList collection={collection} isMinimized={true} />
				</Card>

				<BroadcastSelector mb="1rem" />
			</Container>
			<FooterBase />
		</>
	)
}

export default PublishedInviteList
