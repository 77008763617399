import React, { useState, useEffect } from 'react'
import { Button, HStack } from '@chakra-ui/react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import useFilteredRSVP from '../../../../../hooks/useRSVPFilter'
import { updateRSVPFilters } from '../../../../../../api/actions'
import {
	AccountCircleRounded,
	AccessTimeFilledRounded,
	CheckCircleRounded,
	NotInterestedRounded,
} from '../../../../atoms/Icon'

const filterStyles = {
	h: '32px',
	borderWidth: '1px',
	borderColor: '#DADDE1',
	borderStyle: 'solid',
	backgroundColor: 'white',
	color: 'gray.900',
	fontSize: '0.75rem',
	minW: '80px',
	_hover: {
		cursor: 'pointer',
	},
}

const iconStyles = {
	pl: '6px',
}

const filterWithIconStyles = {
	...filterStyles,
	...iconStyles,
}

const activeStyles = {
	borderColor: 'gray.900',
	backgroundColor: 'gray.900',
	color: 'white',
}

/**
 * Filter Item Component
 * @returns
 */
const FilterItem = ({ onClick, count, Icon, iconColor, label, isActive }) => {
	/** If we have an icon, use styles that support icons */
	let styles = Icon ? filterWithIconStyles : filterStyles

	/** If the element is active, include active styles */
	styles = isActive ? { ...styles, ...activeStyles } : styles

	return (
		<Button sx={styles} onClick={onClick}>
			{Icon && <Icon color={isActive ? 'white' : iconColor} mr="0.25rem" />}
			{count && `(${count}) `} {label}
		</Button>
	)
}

const computeFilterArray = (filter) => {
	let filters
	// If no value, let's select 'all' by creating an empty array
	if (!filter || filter == '' || filter == null) {
		filters = []
	}
	// 'rsvps' and 'removed' are abstraction, so we abstract them here
	else if (filter == 'rsvps') filters = ['awaiting', 'confirmed']
	else if (filter == 'removed') filters = ['archived', 'declined']
	// If if's not an abstraction, turn into an array
	else filters = [filter]

	return filters
}

/**
 * Collection of filters that allow filtering RSVPs
 * @returns
 */
const RSVPStatusFilter = ({ rsvps, hideRemoved, ...rest }) => {
	const dispatch = useDispatch()

	const count = useFilteredRSVP(rsvps, true)

	/**
	 * Retrieve filters used for the rsvps of the current event
	 * */
	const rsvpFilters = useSelector((s) => s.event.rsvpFilters, shallowEqual)

	/**
	 * Local state of the filters
	 */
	const [filters, setFilters] = useState(rsvpFilters)

	/**
	 * Update local state when filters change
	 */
	useEffect(() => {
		setFilters(rsvpFilters)
	}, [rsvpFilters])

	const handleSetFilter = (f) => {
		/**
		 * Retrieve an array of values corresponding to the selected filter
		 * */
		const newFilters = computeFilterArray(f)
		/**
		 * If we select all, remove all filters
		 * */
		if (newFilters.length == 0) {
			setFilters(newFilters)
			dispatch(updateRSVPFilters({ filters: newFilters }))
		} else if (newFilters.some((r) => filters.indexOf(r) >= 0)) {
			/**
			 * Check if the filters contain some of the values of the 'newFilters',
			 * if so, remove them.
			 *  */
			const clone = filters.filter((l) => !newFilters.includes(l))
			setFilters(clone)
			dispatch(updateRSVPFilters({ filters: clone }))
		} else {
			/**
			 * If not, let's add them to the filters
			 * */
			const clone = [...filters, ...newFilters]
			setFilters(clone)
			dispatch(updateRSVPFilters({ filters: clone }))
		}
	}

	return (
		<HStack
			w="fill-available"
			py={['0.25rem']}
			px={['1rem', '0']}
			spacing="0.5rem"
			justifyContent={['flex-start', 'center']}
			overflowX="auto"
			mx={['-1rem', '-1rem']}
			sx={{
				'scrollbarWidth': 'none',
				' msOverflowStyle': 'none',
				'&::-webkit-scrollbar': {
					display: 'none',
				},
				'& button': {
					minW: 'auto',
				},
			}}
			{...rest}>
			<FilterItem label="All" onClick={() => handleSetFilter()} isActive={filters.length == 0} />

			<FilterItem
				label="Smart Invited"
				iconColor="#6C7A88"
				Icon={AccountCircleRounded}
				count={count.pending}
				onClick={() => handleSetFilter('pending')}
				isActive={filters.includes('pending')}
			/>

			<FilterItem
				label="RSVP'd"
				iconColor="#F6B031"
				Icon={AccessTimeFilledRounded}
				count={count.rsvps}
				onClick={() => handleSetFilter('rsvps')}
				isActive={filters.some((el) => ['confirmed', 'awaiting'].includes(el))}
			/>

			<FilterItem
				label="Submitted"
				iconColor="#04D898"
				Icon={CheckCircleRounded}
				count={count.completed}
				onClick={() => handleSetFilter('completed')}
				isActive={filters.includes('completed')}
			/>

			{/* <FilterItem
				label="Participated"
				iconColor="#04D898"
				Icon={CheckCircleRounded}
				count={count.participated}
				onClick={() => handleSetFilter('participated')}
				isActive={filters.includes('participated')}
			/> */}

			{!hideRemoved && (
				<FilterItem
					label="Removed"
					iconColor="#FF1C63"
					Icon={NotInterestedRounded}
					count={count.removed}
					onClick={() => handleSetFilter('removed')}
					isActive={filters.some((el) => ['archived', 'declined'].includes(el))}
				/>
			)}
		</HStack>
	)
}
RSVPStatusFilter.defaultProps = {
	hideRemoved: false,
}

export default RSVPStatusFilter
