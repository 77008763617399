import React from 'react'
import {
	Box,
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Link,
} from '@chakra-ui/react'
import useImageGenerator from '../../../hooks/useImageGenerator'

export const ModalSmartInvite = ({ isOpen, onClose }) => {
	const img = useImageGenerator({
		path: '/assets/images/graphics/modals/smart-invite-modal-graphic',
		ext: 'png',
	})

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="lg">
			<ModalOverlay bg="rgba(10,34,57, 0.9)" />
			<ModalContent mx={['1rem', 'auto']}>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem" mb="1rem">
						<Image src={img.src} srcSet={img.srcset} alt="Smart Invite" boxSize="74px" />
						<Heading size="lg" variant="hero">
							The power of Smart Invites.
						</Heading>
					</VStack>
					<Box display="grid" textAlign="center">
						<VStack spacing="1rem">
							<Text>
								With Smart Invites, you can quickly invite large groups of people by email or sms, and
								easily monitor who has opened their invitation, and who has yet to submit.
							</Text>
							<Text>Plus, they’ll get auto-reminders to submit on time</Text>
							<Text fontSize="0.875rem">
								<Text as="span" color="#0A2239" fontWeight="bold" display="block">
									Please note:
								</Text>
								VidDay cannot guarantee the delivery of invitations and reminder emails or sms — these
								are dependent on individual email/phone providers and user settings.
							</Text>
						</VStack>
					</Box>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button onClick={onClose}>Okay, Thanks</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
