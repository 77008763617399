import React from 'react'
import { Spinner, Box, VStack, StackDivider } from '@chakra-ui/react'
import ContactPickerItem from '../ContactPickerItem'

const ContactPickerList = ({ collection, handleSelect, handleRemove, ...rest }) => {
	return (
		<VStack
			w="full"
			borderTopWidth="1px"
			borderBottomWidth="1px"
			borderColor="#DADDE1"
			pt="0.5rem"
			pb="0.5rem"
			divider={<StackDivider borderColor="#DADDE1" />}
			{...rest}>
			{collection.length == 0 && (
				<Box pt="1rem" pb="0.5rem" w="full" textAlign="center">
					<Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
				</Box>
			)}

			{collection.map((el, i) => (
				<ContactPickerItem key={i} item={el} handleRemove={handleRemove} handleSelect={handleSelect} />
			))}
		</VStack>
	)
}

export default ContactPickerList
