import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { updateEvent } from '../../../../../../api/app/events'
import { chakra } from '@chakra-ui/react'
import Date from '../../../../molecules/controls/Date'
import { useEventContext } from '../../../../../contexts/EventProvider'
import dayjs from 'dayjs'

const schemaDeadline = Yup.object().shape({
	deadline: Yup.string().notRequired().nullable(),
})

export const FormDeadline = ({ onSubmit, label, ...rest }) => {
	const { event } = useEventContext()
	const dispatch = useDispatch()

	/** Define methods and pass in initial state right away that will be used through the form context */
	const { handleSubmit, control, errors, setValue, watch } = useForm({
		mode: 'onSubmit',
		defaultValues: {
			deadline: null,
		},
		resolver: yupResolver(schemaDeadline),
	})

	const values = watch()

	const handleDeadlineSubmit = (data) => {
		// Set Deadline confirmation
		try {
			if (typeof window != 'undefined' && localStorage) {
				const deadlineConfirmed = JSON.parse(localStorage.getItem('deadlineConfirmed')) || []
				if (!deadlineConfirmed.includes(event.uuid)) {
					deadlineConfirmed.push(event.uuid)
					localStorage?.setItem('deadlineConfirmed', JSON.stringify(deadlineConfirmed))
				}
			}
		} catch (err) {
			console.log("Can't access local storage : viewedmedia ", err)
		}
		// Set Deadline
		if (dayjs(data?.deadline).isValid()) {
			let momentDate = dayjs(data.deadline)

			let eventData = {
				id: event.id,
				deadline: momentDate.toISOString(),
				deadlineSet: true,
			}

			dispatch(updateEvent(eventData)) // , true
			// Trigger callback submit
			onSubmit && onSubmit()
		}
	}

	useEffect(() => {
		// Set initial value of our deadline.
		if (event?.deadline && values.deadline == '') {
			setValue('deadline', event?.deadline)
		}
		// Update subsequent deadline changes when it differs from the event.
		if (values.deadline && values.deadline != '' && values.deadline != event?.deadline) {
			// handleDeadlineSubmit(values)
			handleSubmit(handleDeadlineSubmit)()
		}
	}, [values.deadline])

	useEffect(() => {
		if (event?.deadline && event?.deadline != values.deadline) {
			setValue('deadline', event?.deadline)
		}
	}, [event?.deadline])

	return (
		<chakra.form w="full" onSubmit={handleSubmit(handleDeadlineSubmit)} {...rest}>
			<Date
				control={control}
				errors={errors}
				label={label}
				name="deadline"
				placeholder="Submissions are due by"
				useTime
			/>
		</chakra.form>
	)
}

FormDeadline.defaultProps = {
	label: 'Due date',
}

export default FormDeadline
