import { jsPDF } from 'jspdf'
import QRCode from 'qrcode'

export const downloadPrintTemplates = ({ uuid }) => {
	const image = new Image()
	let imgSrc = ''

	let url = `https://vid.day/e/${uuid}?utm_medium=qr&utm_source=${uuid}&utm_campain=guestbook`

	QRCode.toDataURL(url, { margin: 0, errorCorrectionLevel: 'H', width: 1200, height: 1200 }, (err, dataUrl) => {
		if (err) {
			console.error(err)
			return
		}
		imgSrc = dataUrl
	})

	image.src = imgSrc

	if (image.src) {
		// 10 Up Document
		const doc1 = new jsPDF({
			orientation: 'landscape',
			unit: 'px',
			format: [3300, 2550],
		})
		doc1.addImage('/assets/images/templates/Printable-Cards–2x3.5–10UP.jpg', 'jpg', 0, 0, 3300, 2550)
		// First Row
		doc1.addImage(image, 322, 832, 260, 260)
		doc1.addImage(image, 920, 832, 260, 260)
		doc1.addImage(image, 1518, 832, 260, 260)
		doc1.addImage(image, 2118, 832, 260, 260)
		doc1.addImage(image, 2716, 832, 260, 260)
		// Second Row
		doc1.addImage(image, 322, 1882, 260, 260)
		doc1.addImage(image, 920, 1882, 260, 260)
		doc1.addImage(image, 1518, 1882, 260, 260)
		doc1.addImage(image, 2118, 1882, 260, 260)
		doc1.addImage(image, 2716, 1882, 260, 260)
		// Prompt Save
		doc1.save('VidDay-Printable-Cards–2x3.5–10UP.pdf')

		// 2 Up Document
		const doc2 = new jsPDF({
			orientation: 'landscape',
			unit: 'px',
			format: [3300, 2550],
		})
		doc2.addImage('/assets/images/templates/Printable-Cards–4x6-2UP.jpg', 'jpg', 0, 0, 3300, 2550)
		// First Row
		doc2.addImage(image, 816, 1362, 468, 468)
		doc2.addImage(image, 2015, 1362, 468, 468)
		// Prompt Save
		doc2.save('VidDay-Printable-Cards–4x6-2UP.pdf')

		// 1 Up Document
		const doc3 = new jsPDF({
			orientation: 'portrait',
			unit: 'px',
			format: [2550, 3300],
		})
		doc3.addImage('/assets/images/templates/Printable-Cards–8.5x11.jpg', 'jpg', 0, 0, 2550, 3300)
		// First Row
		doc3.addImage(image, 859, 1781, 833, 833)
		// Prompt Save
		doc3.save('VidDay-Printable-Card-8.5x11.pdf')

		// QR Document
		const doc4 = new jsPDF({
			orientation: 'portrait',
			unit: 'px',
			format: [1200, 1200],
		})
		// First Row
		doc4.addImage(image, 0, 0, 1200, 1200)
		// Prompt Save
		doc4.save('VidDay-Printable-Card-QR.pdf')
	}
	return true
}

export default downloadPrintTemplates
