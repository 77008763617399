import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	Button,
	HStack,
	Input,
	FormControl,
	InputGroup,
	InputRightElement,
	Box,
	Text,
	Heading,
	Flex,
} from '@chakra-ui/react'
import ContactPickerList from '../ContactPickerList'
import { logCustomEvent } from '@vidday/utils'
import { postRSVP, updateRSVP } from '../../../../../../api/actions'
import { SearchRounded, BackspaceRounded } from '../../../../atoms/Icon'

export const sanitizeSms = (sms) => sms && sms?.replace(/[-+()\s]/g, '')
export const lower = (value) => value && value?.toLowerCase()

/** Submit RSVP form
 * @param {Object} data - Form data {email, firstName}
 * @param {String} eventId - event id
 * @param {String} uuid uuid of event
 * @param {Boolean} isCreator - is the event creator
 */

export const handleRSVPOnSubmit = ({ data, eventId, isCreator, dispatch }) => {
	const finalData = {
		...data,
		status: 'pending',
		eventId: eventId,
		fromUpload: false,
		notify: !isCreator,
		notifyInvite: isCreator,
	}
	if (data.id) {
		delete data.muteAlert
		dispatch(updateRSVP(data))
	} else {
		dispatch(postRSVP(finalData))
	}

	logCustomEvent({
		action: 'rsvp',
		category: 'engagement',
		label: 'RSVP',
		identifier: eventId,
	})
}

const ProcessContacts = ({ data, rsvps, eventId, isCreator, creatorEmail, onReset }) => {
	const dispatch = useDispatch()

	const [results, setResults] = useState(data)
	const [searchQuery, setSearchQuery] = useState('')
	const [inviteSuccess, setInviteSuccess] = useState(false)

	useEffect(() => {
		if (data.length != results.length) {
			setResults(data)
		}
	}, [data])

	/** Set the search query received by the user */
	const handleSearchQuery = (e) => {
		e.preventDefault()
		setSearchQuery(e.target.value)
	}

	/** Reset the search query */
	const resetSearchQuery = (e) => {
		e.preventDefault()
		setSearchQuery('')
	}

	/** Handler to select a specific item */
	const handleSelect = (id) => {
		// find selected object in array by it's id
		let obj = results.find((r) => r.id == id)
		obj.selected = !obj.selected
		// Find the index of selected object using findIndex method.
		let objIndex = results.findIndex((r) => r.id == id)
		// make final new array of objects by combining updated object.
		const updatedResults = [...results.slice(0, objIndex), obj, ...results.slice(objIndex + 1)]
		// Set results with new object
		setResults(updatedResults)
	}

	/** Handler to remove a specific item */
	const handleRemove = (id) => {
		// Find the index of selected object using findIndex method.
		let objIndex = results.findIndex((r) => r.id == id)
		// Set results with removed object
		setResults([...results.slice(0, objIndex), ...results.slice(objIndex + 1)])
	}

	/** Utility method to remove any duplicates */
	const removeDuplicates = () => {
		// retrieve all emails
		const emails = results?.filter((r) => r.email !== creatorEmail).map((r) => r.email)
		// filter google contacts but eliminate any contacts with dup emails
		const filtered = results?.filter(
			({ email }, index) => !emails.includes(email, index + 1) && email !== creatorEmail
		)
		return filtered
	}

	/** Only use results that do not already exist among RSVPs */
	const validList = () => {
		return results.filter((r) => r.selected)
	}

	/** Submit invitation for selected items */
	const handleBulkSubmit = () => {
		let list = validList()
		const count = list.length

		list.forEach((row) => {
			let email = row.email != '' && row.validEmail ? row.email : undefined // prisma joi won't allow empty string
			let sms = row.sms != '' && row.validSms ? row.sms : undefined // prisma joi won't allow empty string
			var data = {
				data: {
					id: row.id.length > 10 ? row.id : undefined,
					firstName: row.firstName,
					lastName: row.lastName,
					deliveryEmail: email,
					deliverySms: sms,
					muteAlert: true,
				},
				eventId,
				isCreator,
				dispatch: dispatch,
			}
			handleRSVPOnSubmit(data)
		})

		if (count > 1) {
			dispatch({
				type: 'ADD_FLASH_MESSAGE',
				status: 'success',
				message: `Invitation sent to ${count} people`,
			})
		}

		setResults([])
		setInviteSuccess(true)
		setTimeout(onReset, 4000)
	}

	/** Filter results and remove duplicates */
	const filteredResults = results.filter((result) => {
		if (searchQuery != '') {
			return (
				result?.sms?.includes(sanitizeSms(searchQuery)) ||
				lower(result?.email)?.includes(lower(searchQuery)) ||
				lower(result?.firstName)?.includes(lower(searchQuery)) ||
				lower(result?.lastName)?.includes(lower(searchQuery))
			)
		} else {
			return results
		}
	})

	if (inviteSuccess) {
		return (
			<Flex w="full" p="2rem" direction="column" alignItems="center">
				<Heading size="md" as="h4">
					Success!
				</Heading>
				<Text>Your list has been sent an invitation email and added to the list below.</Text>
			</Flex>
		)
	}

	return (
		<>
			<Box w="full" p={['1rem', '2rem']}>
				<FormControl isInvalid={false} mb="1rem">
					<InputGroup>
						<Input
							name="contactField"
							placeholder="Search for a contact ..."
							value={searchQuery}
							onChange={handleSearchQuery}
						/>
						<InputRightElement
							_hover={{ cursor: 'pointer' }}
							onClick={searchQuery != '' ? resetSearchQuery : handleSearchQuery}
							children={
								searchQuery != '' ? (
									<BackspaceRounded color="gray.300" />
								) : (
									<SearchRounded color="gray.300" />
								)
							}
						/>
					</InputGroup>
				</FormControl>

				<HStack justifyContent="space-between">
					<Text mb="1rem">
						<strong>{results.length}</strong> contact{results.length > 1 ? 's' : ''} loaded
					</Text>
					<Text mb="1rem">
						<strong>{validList().length}</strong> contact{validList().length > 1 ? 's' : ''} selected
					</Text>
				</HStack>

				<ContactPickerList
					collection={filteredResults}
					mb="2rem"
					handleSelect={handleSelect}
					handleRemove={handleRemove}
				/>

				<HStack spacing="1rem" w="full" justifyContent="center">
					<Button variant="outline" size="md" onClick={onReset}>
						Cancel
					</Button>
					<Button
						type="submit"
						variant="solid"
						size="md"
						disabled={validList().length == 0}
						onClick={handleBulkSubmit}>
						{`Send ${validList().length} `} Invite{validList().length > 1 ? 's' : ''}
					</Button>
				</HStack>
			</Box>
		</>
	)
}

export default ProcessContacts
