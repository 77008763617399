import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import {
	Box,
	Container,
	Heading,
	Text,
	chakra,
	SimpleGrid,
	useDisclosure,
	Flex,
	Link,
	Image,
	VStack,
	Stack,
	Button,
} from '@chakra-ui/react'
import Card from '../../../atoms/Card'
import FooterBase from '../../../molecules/FooterBase'
import { useRSVPFiltered } from '../../../../hooks/useRSVPs'
import RSVPStatusFilter from '../atoms/RSVPStatusFilter'
import RSVPList from '../atoms/RSVPList'
import TabsProvider, { useTabsContext } from '../../../../contexts/TabsProvider'
import { useEventHandlersV2 } from '../../../../hooks/useEventHandlers'
import GoogleImport from '../molecules/GoogleImport'
import CSV from '../molecules/CSV'
import Individual from '../molecules/Individual'
import { BroadcastSelector } from '../../Broadcast/selectors/BroadcastSelector'
import ModalInvitationSetDeadline from '../../../organisms/ModalInvitationSetDeadline'
import { useEventContext } from '../../../../contexts/EventProvider'
import CopyLink from '../../../molecules/CopyLink'
import InvitationMethods from '../../../molecules/InvitationMethods'
import Controls from '../molecules/Controls'
import NavTabs from '../../Invitation/molecules/NavTabs'
import useScrollTopOnMount from '../../../../hooks/useScrollTopOnMount'
import { isMobile } from 'react-device-detect'
import { AccountCircleRounded, InfoRounded, Google, UploadFileFullRounded } from '../../../atoms/Icon'
import useImageGenerator from '../../../../hooks/useImageGenerator'
import { ModalSmartInvite } from '../../../organisms/ModalSmartInvite'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import { downloadPrintTemplates } from '../../../molecules/GuestbookTemplateDownloader'

const buttonStyles = {
	display: 'flex',
	alignItems: 'center',
	fontWeight: 'bold',
	color: '#6C7A88',
	py: '1rem',
	justifyContent: 'center',
	borderBottomWidth: '3px',
	borderColor: 'transparent',
	_hover: {
		cursor: 'pointer',
		color: 'link',
	},
}

const activeButtonStyles = {
	color: 'link',
	borderBottomWidth: '3px',
	borderColor: 'link',
}

const ImportTabs = ({ tab }) => {
	const { active, setActiveTab } = useTabsContext(tab)
	const activeStyles = { ...buttonStyles, ...activeButtonStyles }
	return (
		<Box w="full" borderBottomWidth="1px" borderStyle="solid" borderColor="#DADDE1">
			<SimpleGrid mx="auto" columns={[3]} spacing={['.5rem', '1rem']} w="full" maxW="600px">
				<chakra.a
					sx={active == 'individual' ? activeStyles : buttonStyles}
					onClick={() => setActiveTab('individual')}>
					<AccountCircleRounded mr="0.25rem" /> {!isMobile ? 'Single Send' : 'Single'}
				</chakra.a>
				<chakra.a sx={active == 'google' ? activeStyles : buttonStyles} onClick={() => setActiveTab('google')}>
					<Google mr="0.25rem" /> {!isMobile ? 'Google Import' : 'Contacts'}
				</chakra.a>
				<chakra.a sx={active == 'csv' ? activeStyles : buttonStyles} onClick={() => setActiveTab('csv')}>
					<UploadFileFullRounded mr="0.25rem" /> {!isMobile ? 'Upload CSV' : 'CSV File'}
				</chakra.a>
			</SimpleGrid>
		</Box>
	)
}

const tabs = [
	{
		id: 'individual',
		path: '/individual',
	},
	{
		id: 'google',
		path: '/google',
	},
	{
		id: 'csv',
		path: '/csv',
	},
]

const CoverImageMobile = () => {
	const img = useImageGenerator({
		path: '/assets/images/graphics/vidday-smart-invites-header-mobile',
		ext: 'png',
	})
	return (
		<Image
			display={['block', 'none']}
			src={img.src}
			srcSet={img.srcset}
			alt="Smart Invites Desktop"
			width="271px"
			height="75px"
		/>
	)
}

const CoverImageDesktop = () => {
	const img = useImageGenerator({
		path: '/assets/images/graphics/vidday-smart-invites-header-desktop',
		ext: 'png',
	})
	return (
		<Image
			display={['none', 'block']}
			src={img.src}
			srcSet={img.srcset}
			alt="Smart Invites Desktop"
			width="417px"
			height="83px"
		/>
	)
}

const NoInviteList = () => {
	const image = useImageGenerator({
		path: '/assets/images/graphics/vidday-invite-list-crystal-ball',
		ext: 'png',
	})

	return (
		<VStack maxW="390px" textAlign="center">
			<Image src={image.src} srcSet={image.srcset} width="57px" height="57px" />
			<Heading size="sm">Participants are in your future.</Heading>
			<Text>
				People who are invited through Smart Invites, or have RSVP’d to your invite link will appear here in a
				list.
			</Text>
		</VStack>
	)
}

const SomeoneMissing = () => {
	const image = useImageGenerator({
		path: '/assets/images/graphics/vidday-invite-list-missing',
		ext: 'png',
	})

	return (
		<VStack maxW="472px" textAlign="center" pt="2rem" mx="auto">
			<Image src={image.src} srcSet={image.srcset} width="29px" height="29px" />
			<Heading size="sm">Someone missing from your list?</Heading>
			<Text>
				Only people who are invited through Smart Invites, or have RSVP’d to your invite link will appear on
				your invite list.
			</Text>
		</VStack>
	)
}

const Active = () => {
	useScrollTopOnMount()
	const { event } = useEventContext()
	const { rsvps, collection } = useRSVPFiltered()
	const { role } = useAuthContext()

	const { uuid, tab } = useParams()
	const handlers = useEventHandlersV2(uuid)

	const modal = useDisclosure()
	const deadlineModal = useDisclosure()

	const handleDownloadTemplates = () => {
		downloadPrintTemplates({ uuid: event.uuid })
	}

	const templatesQr = useImageGenerator({
		path: '/assets/images/pages/invite-list/qr-templates',
		ext: 'png',
	})

	// open ModalInvitationSetDeadline if no deadline set
	useEffect(() => {
		if (event && typeof window != 'undefined' && localStorage && role !== 'admin') {
			const deadlineConfirmed = localStorage.getItem('deadlineConfirmed') || []
			if (!deadlineConfirmed.includes(event.uuid)) deadlineModal.onOpen()
		}
	}, [event])

	return (
		<>
			<Helmet>
				<title>VidDay - Invitation Manager</title>
			</Helmet>

			<ModalInvitationSetDeadline onClose={deadlineModal.onClose} isOpen={deadlineModal.isOpen} event={event} />

			<Controls />

			<Container maxW="container.md">
				<Box textAlign="center" py="2rem">
					<Heading as="h2" size="xl" variant="hero" pb="1rem">
						Invitation
					</Heading>
					<NavTabs defaultIndex={0} />
				</Box>

				{event.guestbook && (
					<Card alignItems="center" mb="1rem">
						<Stack
							w="full"
							p="2rem"
							direction={['column', 'row']}
							justifyContent={['space-between', 'start']}
							alignItems="center"
							spacing="1rem">
							<Image
								src={templatesQr.src}
								srcSet={templatesQr.srcset}
								boxSize="99px"
								alt="QR Templates"
							/>
							<Box w="full" textAlign={['center', 'left']}>
								<Heading as="h3" size="md">
									QR Cards + Posters
								</Heading>
								<Text color="#47596A" fontSize="1rem" lineHeight="1.25rem" mt=".25rem">
									Make it easy for everyone to upload their memories and messages.
								</Text>
							</Box>
							<Flex w="full" justifyContent={['center', 'end']}>
								<Button onClick={handleDownloadTemplates}>Download</Button>
							</Flex>
						</Stack>
					</Card>
				)}

				<TabsProvider tabs={tabs} active={tab} baseUrl={handlers.invitation_list}>
					<Card alignItems="center" mb="1rem">
						<Flex
							w="full"
							direction="column"
							bg="#006666"
							pos="relative"
							minH="125px"
							alignItems="center"
							justifyContent="center">
							<Image
								pos="absolute"
								top={0}
								left={0}
								src="/assets/images/graphics/vidday-smart-invites-motherboard-graphic.svg"
								alt="Background lines image"
								width="123px"
								height="42px"
							/>

							<Link
								onClick={modal.onOpen}
								boxSize={'34px'}
								bg="#014343"
								borderRadius="base"
								alignItems="center"
								justifyContent="center"
								display="flex"
								pos="absolute"
								top="0.5rem"
								right="0.5rem"
								sx={{
									'> svg': {
										color: 'rgba(255,255,255,0.7)',
									},
									'_hover': {
										'> svg': { color: 'white' },
									},
								}}>
								<InfoRounded width="1rem" />
								{/* <Icon as={InfoRounded} boxSize="0.75rem" />  */}
							</Link>

							<CoverImageMobile />
							<CoverImageDesktop />
							<ModalSmartInvite isOpen={modal.isOpen} onClose={modal.onClose} />
						</Flex>

						<ImportTabs tab={tab} />
						{(!tab || tab == 'individual') && <Individual />}
						{tab == 'google' && <GoogleImport />}
						{tab == 'csv' && <CSV />}
					</Card>
				</TabsProvider>

				<Container maxW="container.sm">
					<Box textAlign="center" mx={['1rem', 0]} pt="1.5rem" mb="1rem">
						<Heading as="h3" size="md">
							Or share your invite link:
						</Heading>
						<CopyLink event={event} fontSize="14px" />
					</Box>
				</Container>

				<InvitationMethods theme="light" mb="2rem" />

				{/* <QRCodeSelector mb="2rem" /> */}

				{/* {rsvps.length > 0 && ( */}
				<Card px={['1rem', '2rem']} py="2rem" mb="1rem" alignItems="center">
					<Heading size="md" mb="0.5rem">
						Invite List
					</Heading>
					<RSVPStatusFilter rsvps={rsvps} mb="2rem" />
					<RSVPList collection={collection} isMinimized={false} />
					{collection.length == 0 && <NoInviteList />}
				</Card>
				{/* )} */}

				<BroadcastSelector />

				{collection.length > 0 && <SomeoneMissing />}
				<FooterBase />
			</Container>
		</>
	)
}

export default Active
