import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Box, VStack, StackDivider, Spinner } from '@chakra-ui/react'
import RSVPItem from '../RSVPItem'
import { useAuthContext } from '../../../../../contexts/AuthProvider'
import { useMedia } from '../../../../../hooks/useMedia'
// import useImageGenerator from '../../../../../hooks/useImageGenerator'

// deprecated for now - never show an EmptyView
//export const EmptyView = ({ title, text }) => {
//	const img2 = useImageGenerator({
//		path: '/assets/images/graphics/invite-list-appearance',
//		ext: 'png',
//	})
//
//	return (
//		<VStack maxW="520px">
//			<Image src={img2.src} srcSet={img2.srcset} alt="Your Invite list will appear here" boxSize="109px" />
//			<Heading as="h3" size="md">
//				<strong>{title}</strong>
//			</Heading>
//			<Text textAlign={'center'} lineHeight="1.25rem" color="#47596a">
//				{text}
//			</Text>
//		</VStack>
//	)
//}

//EmptyView.defaultProps = {
//	title: 'Your invite list will appear here.',
//}

const RSVPList = ({ collection, isMinimized }) => {
	const { role, isImpersonated } = useAuthContext()
	const media = useMedia()
	/**
	 * Are we fetching the event ?
	 */
	const isFetching = useSelector((s) => s.event.isFetching, shallowEqual)

	if (!isFetching) {
		if (collection.length > 0) {
			const items = collection.map((el, i) => {
				let mediaId = null
				if (el.status == 'completed') {
					mediaId = media.find((m) => m?.contributorId === el.userId)?.id
				}
				return (
					<RSVPItem
						key={i}
						item={el}
						mediaId={mediaId}
						role={role}
						isImpersonated={isImpersonated}
						isMinimized={isMinimized}
					/>
				)
			})

			return (
				<VStack
					w="full"
					borderTopWidth="1px"
					borderTopColor="#DADDE1"
					pt="0.5rem"
					divider={<StackDivider borderColor="#DADDE1" />}>
					{items}
				</VStack>
			)
		} else {
			/** return null - nothing should be displayed **/
			return null
		}
	}
	return (
		<Box p="2rem">
			<Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
		</Box>
	)
}

export default RSVPList
